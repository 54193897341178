document.addEventListener('DOMContentLoaded', function () {
    var video = document.querySelector('.slider__slide iframe')
    if (!video) return;

    var player = new Vimeo.Player(video);

    handleOverlay(video, player)
    handleVideoBtns(video, player)
});

function handleOverlay(video, player) {
    
    var overlay = document.querySelector('.video-placeholder') 
    if (window.innerWidth < 840) {
        setTimeout(function() {
            sizeVideo(video)
            overlay.style.transition = 'all 0.8s';
            overlay.style.opacity = 0;
        }, 2000);
        return
    }

    player.on('play', function() {
        sizeVideo(video)
        setTimeout(function() {
            overlay.style.transition = 'all 0.8s';
            overlay.style.opacity = 0;
            overlay.style.pointerEvents = 'none';
        }, 500); 
    });  
}

function handleVideoBtns(video, player) {
    var overlay = document.querySelector('.video-placeholder') 
    var playBtn = document.querySelector('.slider .play-button')
    var videoBar = document.querySelector('.video-bar')
    var volBar = document.querySelector('#volumeSwitch')
    var volOn= document.querySelector('#volumeOn')
    var volOff= document.querySelector('#volumeOff')


    var isAutoPlay = video.getAttribute('src').includes('autoplay=1')
    var isSound = false;

    function toggleSound (state, player) {
        if (!state) {
            volBar.classList.toggle('on')
            volBar.classList.toggle('off')
            volOff.style.visibility = 'hidden'
            volOn.style.visibility = 'visible'
            volBar.checked = false;
            player.setVolume(0);
        } else {
            volBar.classList.toggle('on')
            volBar.classList.toggle('off')
            volOff.style.visibility = 'visible'
            volOn.style.visibility = 'hidden'
            volBar.checked = true;
            player.setVolume(0.66);
        }
    }

    if (isAutoPlay) {
        playBtn.style.display= 'none';
        videoBar.style.display = 'block';
        videoBar.style.zIndex = '5';

        playBtn.addEventListener('click', function () {
            player.play().then(function() {
                sizeVideo(video)
                playBtn.style.display= 'none';
                videoBar.style.display = 'block';
              }).catch(function(error) {
                console.log(error)
              });
        })
    } else {
        overlay.style.display = 'none';
    }

    volBar.addEventListener('click', function() {
        isSound=!isSound
        toggleSound(isSound, player)
    })
}

function sizeVideo(video) {
    coverViewport(video, true);
    window.addEventListener('resize', function () {
        coverViewport(video, true)
    });
}

function coverViewport(element, scaleToParent) {
    if (typeof scaleToParent === 'undefined') { scaleToParent = false; }

    var viewportWidth, viewportHeight;
    if (scaleToParent) {
        viewportWidth = element.parentElement.clientWidth;
        viewportHeight = element.parentElement.clientHeight;
    } else {
        viewportWidth = window.innerWidth;
        viewportHeight = window.innerHeight;
    }

    var elementWidth = element.getAttribute('width');
    var elementHeight = element.getAttribute('height');

    // Aspect ratio = height / width
    var viewportRatio = viewportHeight / viewportWidth;
    var videoRatio = elementHeight / elementWidth;

    // console.log('viewportHeigt: ', viewportHeight )
    // console.log('viewportWidth: ', viewportWidth )
    // console.log('Viewport: ', viewportRatio, ' Video: ', videoRatio);


    var newWidth = (elementWidth / elementHeight) * viewportHeight
    var newHeight = (elementHeight / elementWidth) * viewportWidth;
    var newTop = 0;

    if (viewportRatio > videoRatio) {
        // set video height to the viewport, and extend the video beyond the viewport width, i.e. portrait viewport
        // console.log('crop left and right');
        element.style.height = viewportHeight +'px';
        element.style.width = newWidth +'px';
        element.style.left = '-' + (newWidth - viewportWidth) / 2 +'px';
        element.style.top = '0';
    } else if (viewportRatio < videoRatio) {
        // set video width to the viewport, and extend the video beyond the viewport height, i.e. landscape viewport
        // console.log('crop top and bottom');
        element.style.width = viewportWidth +'px';
        element.style.height = newHeight +'px';
        element.style.left = '0';
        element.style.top = '-' + (newHeight - viewportHeight) / 2 +'px';
    } else {
        // set the video width AND height to the viewport size, i.e. viewport is the same ratio as the video
        // console.log('no crop');
        element.style.height = viewportHeight +'px';
        element.style.width = viewportWidth +'px';
        element.style.left = '0';
        element.style.top = '0';
    }
}